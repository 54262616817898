import { signup } from "@/api";
import sec3Logo from "@/assets/imgs/sec3-01.svg";
import {
  Box,
  Button,
  FormCheckbox,
  FormInput,
  MobileOnboardingHeader,
} from "@/components";
import { GITHUB_OAUTH_API, ONBOARDING_TEXT, ONBOARDING_TITLE } from "@/config";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Success } from "./Success";
import "./style.scss";

const validationSchema = Yup.object().shape({
  organization: Yup.string().required("Organization is required"),
  username: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Valid email is required")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  //   "Must contain at least one letter and number"
  // ),
  agreement: Yup.bool().oneOf(
    [true],
    "Must accept terms of service and privacy policy.",
  ),
});

export const Mobile = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsSignup(false);
    return () => {};
  }, []);

  return (
    <div className="signup-mobile">
      {!isSignup ? (
        <div className="signup-mobile-content">
          <img src={sec3Logo} alt="logo" className="signup-mobile-logo" />
          <div className="signup-mobile-title mt-auto">{ONBOARDING_TITLE}</div>
          <div className="signup-mobile-desc mt-4">{ONBOARDING_TEXT}</div>
        </div>
      ) : (
        <MobileOnboardingHeader />
      )}

      <div className={classNames("signup-mobile-bottom", { signup: isSignup })}>
        {!isSignup ? (
          <>
            <Button onClick={() => navigate("/login")}> Sign in</Button>
            <Button
              className="mt-3"
              color="transparent"
              onClick={() => setIsSignup(true)}
            >
              {" "}
              Sign up with email{" "}
            </Button>
            <Button
              color="transparent"
              className="mt-3"
              onClick={() => window.open(GITHUB_OAUTH_API, "_self")}
            >
              {" "}
              Sign up using GitHub{" "}
            </Button>
          </>
        ) : isSignupSuccess ? (
          <Success email={email} />
        ) : (
          <Box title="Sign up using email">
            <Formik
              initialValues={{
                username: "",
                organization: "",
                email: "",
                password: "",
                agreement: false,
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                setError("");
                try {
                  setEmail(values.email);
                  await signup({
                    username: values.username,
                    organization: values.organization,
                    email: values.email,
                    password: values.password,
                  });
                  setIsSignupSuccess(true);
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  setError(error.response.data.message);
                }
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <div>
                    <Form>
                      <FormInput
                        label="Organization"
                        name="organization"
                        isRequired
                        placeholder="My Company"
                        className="mt-3"
                        id="signup-organization"
                      />
                      <FormInput
                        label="Name for Billing"
                        name="username"
                        isRequired
                        placeholder="Name for Billing"
                        className="mt-3"
                        id="signup-username"
                      />
                      <FormInput
                        label="Email"
                        name="email"
                        isRequired
                        placeholder="admin@mycompany.com"
                        className="mt-3"
                        id="signup-company"
                      />
                      <FormInput
                        label="Password"
                        type="password"
                        name="password"
                        isRequired
                        placeholder="At least 8 characters with both letters and numbers"
                        className="mt-3"
                        id="signup-password"
                      />

                      <FormCheckbox
                        name="agreement"
                        id="signup-accept"
                        className="mt-3"
                      >
                        I accept the{" "}
                        <a
                          href="https://sec3.dev/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <u>terms of service</u>
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://sec3.dev/privacy-policy"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <u>privacy policy</u>
                        </a>
                        <div className="invalid-feedback">
                          You must agree the terms of service and privacy policy
                          to continue
                        </div>
                      </FormCheckbox>

                      {error && (
                        <div className="text-left mt-3 color-red">{error}</div>
                      )}
                      <div className="mt-3">
                        <Button
                          type="submit"
                          color="primary"
                          className="w-100"
                          isLoading={isSubmitting}
                          id="signup-submit"
                        >
                          Continue
                        </Button>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        )}
      </div>
    </div>
  );
};
