import { PublicRoutes } from "@/components";
import AdminRoutes from "@/components/ProtectedRoutes/AdminRoutes";
import ProtectedRoutes from "@/components/ProtectedRoutes/ProtectedRoutes";
import {
  AlertsProvider,
  AuthProvider,
  DataProvider,
  MonitorProvider,
  SveProvider,
  WebSocketProvider,
} from "@/contexts";
import { useTheme } from "@/hooks";
import {
  ForgotPasswordPage,
  ResendEmailPage,
  ResetPasswordPage,
  LoadingPage,
} from "@/pages";
import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const AdminPage = lazy(() => import("@/admins/Admin"));
const EditUserPage = lazy(() => import("@/admins/EditUser"));

const ManageUserPage = lazy(() => import("./admins/ManageUsers"));
const CreateUserPage = lazy(() => import("./admins/CreateUser"));
const AdminBotsPage = lazy(() => import("./admins/Bots"));
const TaskStatisticsPage = lazy(() => import("./admins/TaskStatistics"));
const ManageBotTemplatesPage = lazy(
  () => import("./admins/ManageBotTemplates"),
);
const SveStatisticsPage = lazy(() => import("./admins/SveStatistics"));

const AccountPage = lazy(() => import("./pages/Account"));
const ActivationPage = lazy(() => import("./pages/Activation"));
const AddBotTxPage = lazy(() => import("./pages/AddBotTx"));
const HomePage = lazy(() => import("./pages/Home"));
const InvitePage = lazy(() => import("./pages/Invite"));
const ScoredTxPage = lazy(() => import("./pages/ScoredTx"));

const LoginPage = lazy(() => import("./pages/Login"));
const MfaPage = lazy(() => import("./pages/Mfa"));
const MfaPromptPage = lazy(() => import("./pages/MfaPrompt"));
const MfaSetupPage = lazy(() => import("./pages/MfaSetup"));
const MfaSetupPhonePage = lazy(() => import("./pages/MfaSetupPhone"));
const MfaVerifyPhonePage = lazy(() => import("./pages/MfaVerifyPhone"));
const MfaSetupTotpPage = lazy(() => import("./pages/MfaSetupTotp"));
const MfaVerifyTotpPage = lazy(() => import("./pages/MfaVerifyTotp"));
const MfaSuccessPage = lazy(() => import("./pages/MfaSuccess"));
const MonitorPage = lazy(() => import("./pages/Monitor"));
const NotFoundPage = lazy(() => import("./pages/NotFound"));
const ProgramPage = lazy(() => import("./pages/Program"));
const ReportPage = lazy(() => import("./pages/Report"));
const ResultPage = lazy(() => import("./pages/Result"));
const SignupPage = lazy(() => import("./pages/Signup"));
const SvePage = lazy(() => import("./pages/Sve"));
const TxBuilderPage = lazy(() => import("./pages/TxBuilder"));
const VerifyEmailPage = lazy(() => import("./pages/VerifyEmail"));
const VerifySlackPage = lazy(() => import("./pages/VerifySlack"));

const App = () => {
  const theme = useTheme();
  return (
    <div className={`app ${theme}`}>
      <Router>
        <WebSocketProvider>
          <SveProvider>
            <AuthProvider>
              <MonitorProvider>
                <DataProvider>
                  <AlertsProvider>
                    <div className="route-outlet">
                      <Suspense fallback={<LoadingPage />}>
                        <Routes>
                          <Route
                            path="/signup/resendemail"
                            element={<ResendEmailPage />}
                          />
                          <Route
                            path="/forgotpassword"
                            element={<ForgotPasswordPage />}
                          />
                          <Route
                            path="/resetpassword"
                            element={<ResetPasswordPage />}
                          />
                          <Route
                            path="/activate"
                            element={<ActivationPage />}
                          />
                          <Route path="/invite" element={<InvitePage />} />
                          <Route path="/mfa" element={<MfaPage />} />
                          <Route element={<PublicRoutes />}>
                            <Route path="/" element={<SignupPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/signup" element={<SignupPage />} />
                          </Route>
                          <Route element={<ProtectedRoutes />}>
                            <Route path="/task" element={<HomePage />} />
                            <Route
                              path="/task/:taskId"
                              element={<ReportPage />}
                            />
                            <Route
                              path="/task/:taskId/:reportId"
                              element={<ProgramPage />}
                            />

                            <Route path="/monitor" element={<MonitorPage />} />
                            <Route
                              path="/scored_tx"
                              element={<ScoredTxPage />}
                            />
                            <Route
                              path="/tx_builder"
                              element={<TxBuilderPage />}
                            />
                            <Route path="/account" element={<AccountPage />} />
                            <Route
                              path="/mfaprompt"
                              element={<MfaPromptPage />}
                            />
                            <Route
                              path="/mfasetup"
                              element={<MfaSetupPage />}
                            />
                            <Route
                              path="/setupphone"
                              element={<MfaSetupPhonePage />}
                            />
                            <Route
                              path="/verify_email"
                              element={<VerifyEmailPage />}
                            />
                            <Route
                              path="/add_slack"
                              element={<VerifySlackPage />}
                            />
                            <Route
                              path="/add_bot_tx"
                              element={<AddBotTxPage />}
                            />
                            <Route
                              path="/verifyphone"
                              element={<MfaVerifyPhonePage />}
                            />
                            <Route
                              path="/setuptotp"
                              element={<MfaSetupTotpPage />}
                            />
                            <Route
                              path="/verifytotp"
                              element={<MfaVerifyTotpPage />}
                            />
                            <Route
                              path="/mfasuccess"
                              element={<MfaSuccessPage />}
                            />
                            <Route path="/sves" element={<SvePage />} />
                            <Route
                              path="/payment/success"
                              element={<ResultPage success={true} />}
                            />
                            <Route
                              path="/payment/cancel"
                              element={<ResultPage success={false} />}
                            />
                          </Route>
                          <Route path="/admin" element={<AdminRoutes />}>
                            <Route path="users" element={<ManageUserPage />} />
                            <Route
                              path="new_user"
                              element={<CreateUserPage />}
                            />
                            <Route
                              path="edituser/:user_id"
                              element={<EditUserPage />}
                            />
                            <Route
                              path="task/:user_id"
                              element={<HomePage />}
                            />
                            <Route
                              path="alerts/:user_id"
                              element={<MonitorPage />}
                            />
                            <Route
                              path="tasks"
                              element={<TaskStatisticsPage />}
                            />
                            <Route
                              path="sves"
                              element={<SveStatisticsPage />}
                            />
                            <Route
                              path="bottemplates"
                              element={<ManageBotTemplatesPage />}
                            />
                            <Route path="bots" element={<AdminBotsPage />} />
                            <Route path="" element={<AdminPage />} />
                          </Route>
                          <Route path="/404" element={<NotFoundPage />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </Suspense>
                    </div>
                  </AlertsProvider>
                </DataProvider>
              </MonitorProvider>
            </AuthProvider>
          </SveProvider>
        </WebSocketProvider>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
