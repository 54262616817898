import { format } from "date-fns";
import {
  RawReport,
  Issue,
  Report,
  IssueSortingCriterion,
  DTValue,
  PlanName,
} from "@/types";
import { subHours, subDays, subMonths } from "date-fns";
import SolanaIconImg from "@/assets/solana-icon.png";
import EthereumIconImg from "@/assets/ethereum-icon.png";
import PolygonIconImg from "@/assets/polygon-icon.png";

/* Convert number of bytes into human-friendly size of data */
export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatNumber = (num: number): string => {
  return Math.round(num).toLocaleString();
};

export const priorityToString = (priority: number): string => {
  if (priority >= 10) {
    return "Critical";
  } else if (priority >= 8) {
    return "High";
  } else if (priority >= 6) {
    return "Medium";
  } else if (priority >= 0) {
    return "Low";
  } else {
    return "Unknown Severity";
  }
};

export const downloadFile = (fileName: string, content: any) => {
  const aLink = document.createElement("a");
  const blob = new Blob([content]);
  aLink.download = fileName;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
  URL.revokeObjectURL(aLink.href);
  aLink.remove();
};

export const organizeReport = (
  rawReport: RawReport,
  showIgnored: boolean,
): Report => {
  const untrustfulAccounts = rawReport.raceData.untrustfulAccounts.map(
    (issue: Issue, index: number): Issue => {
      return {
        ...issue,
        type: "Untrustful Account",
        isNew: rawReport.newUntrustfulAccounts.includes(index),
        index: index + 1,
      };
    },
  );
  const unsafeOperations = rawReport.raceData.unsafeOperations.map(
    (issue: Issue, index: number): Issue => {
      return {
        ...issue,
        type: "Unsafe Operation",
        isNew: rawReport.newUnsafeOperations.includes(index),
        index: index + 1,
      };
    },
  );
  const cosplayAccounts = rawReport.raceData.cosplayAccounts.map(
    (issue: Issue, index: number): Issue => {
      return {
        ...issue,
        type: "Cosplay Account",
        isNew: rawReport.newCosplayAccounts.includes(index),
        index: index + 1,
      };
    },
  );
  const issues = [
    ...untrustfulAccounts,
    ...unsafeOperations,
    ...cosplayAccounts,
  ];

  return {
    id: rawReport.id,
    created_at: rawReport.created_at,
    title: rawReport.title,
    issues: issues.filter((issue: Issue) => {
      return showIgnored || !issue.ignore;
    }),
    verData: rawReport.verData,
    projectDir: rawReport.projectDir,
    comparedWithBase: rawReport.comparedWithBase,
    baseGitCommitLog: rawReport.baseGitCommitLog,
    currGitCommitLog: rawReport.currGitCommitLog,
    currentGitUrl: rawReport.currentGitUrl,
  };
};

export const issueCompareFunction = (
  criterion: IssueSortingCriterion,
): ((a: Issue, b: Issue) => number) => {
  const compareType = (a: Issue, b: Issue): number => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  };

  const compareSeverity = (a: Issue, b: Issue): number => {
    return b.priority - a.priority;
  };

  const compareNewness = (a: Issue, b: Issue): number => {
    if (a.isNew && !b.isNew) {
      return -1;
    }
    if (!a.isNew && b.isNew) {
      return 1;
    }
    return 0;
  };

  switch (criterion) {
    case IssueSortingCriterion.Type:
      return compareType;
    case IssueSortingCriterion.Severity:
      return compareSeverity;
    case IssueSortingCriterion.Newness:
      return compareNewness;
    default:
      return compareType;
  }
};

const planRank = new Map<PlanName, number>([
  ["Free", 1],
  ["Basic", 2],
  ["Build", 3],
  ["Scale", 4],
  ["Diamond", 5],
]);

// Compare function for sorting plans
export const comparePlan = (a: PlanName, b: PlanName): number => {
  return planRank.get(a) - planRank.get(b);
};

export const capitalize = (str: string) => {
  return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : "";
};

export const reduceString = (str: string, digit: number = 3) => {
  if (str.length > 2 * digit + 2) {
    const first = str.substring(0, digit);
    const last = str.substring(str.length - digit);
    return first + "..." + last;
  }
  return str;
};

export const prettifyUrl = (params: string) => {
  if (params.startsWith("https://") || params.startsWith("http://")) {
    const splits = params.split("/");
    return splits[splits.length - 1];
  }
  return params;
};

export const formatTimeDiffFromNow = (inputTime: Date): string => {
  const MINUTE = 60 * 1000;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;

  const time = new Date(inputTime);

  let date_label = "a few seconds ago";
  const msBetween = new Date().getTime() - time.getTime();
  if (time) {
    const daysBetween = Math.floor(msBetween / DAY);
    const hoursBetween = Math.floor(msBetween / HOUR);
    const minutesBetween = Math.floor(msBetween / MINUTE);

    if (daysBetween > 30) {
      date_label = `${format(time, "MMM dd, yyyy hh:mm:ss aa")}  ${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`;
    } else if (daysBetween > 0) {
      if (daysBetween === 1) {
        date_label = daysBetween + " day ago";
      } else {
        date_label = daysBetween + " days ago";
      }
    } else if (hoursBetween > 0) {
      if (hoursBetween === 1) {
        date_label = hoursBetween + " hour ago";
      } else {
        date_label = hoursBetween + " hours ago";
      }
    } else if (minutesBetween > 0) {
      if (minutesBetween === 1) {
        date_label = minutesBetween + " min ago";
      } else {
        date_label = minutesBetween + " mins ago";
      }
    }
  }
  return date_label;
};

export const validateAddress = (address: string) => {
  const regex = /^[a-zA-HJ-NP-Z0-9]{32,44}$/;
  return regex.test(address);
};

export const maskEmail = (str: string) => {
  if (!str) {
    return "";
  } else {
    const [name, fullDomain] = str.split("@");
    let maskedName = "";
    if (name.length === 1) {
      maskedName = "*";
    } else if (name.length === 2) {
      maskedName = `${name[0]}*`;
    } else {
      maskedName = `${name[0]}${"*".repeat(name.length - 2)}${
        name[name.length - 1]
      }`;
    }
    return `${maskedName}@${fullDomain}`;
  }
};

export const maskPhoneNumber = (str: string) => {
  const length = str.length;
  if (length === 0) return "";
  let left = 2;
  let right = length - 4;
  if (length <= 10) {
    left = Math.floor(length / 2.5 - 1);
    right = Math.floor(length / 1.5 + 1);
  }
  return str
    .split("")
    .map((digit, index) => (index > left && index < right ? "*" : digit))
    .join("");
};

export const makeAddress = (chain: string, network: string, addr: string) => {
  switch (chain) {
    case "Solana":
      const url = `https://solscan.io/account/${addr}`;
      switch (network) {
        case "Devnet":
          return `${url}?cluster=devnet`;
        default:
          return url;
      }
    case "Ethereum":
      return `https://etherscan.io/address/${addr}`;
    case "Polygon zkEVM":
      switch (network) {
        case "Testnet": // Kept for backward compatibility.
          return `https://testnet-zkevm.polygonscan.com/address/${addr}`;
        default:
          return `https://zkevm.polygonscan.com/address/${addr}`;
      }
    default:
      break;
  }
  return "";
};

export const makeTx = (chain: string, network: string, tx: string) => {
  switch (chain) {
    case "Solana":
      const url = `https://solscan.io/tx/${tx}`;
      switch (network) {
        case "Devnet":
          return `${url}?cluster=devnet`;
        default:
          return url;
      }
    case "Ethereum":
      return `https://etherscan.io/tx/${tx}`;
    case "Polygon zkEVM":
      switch (network) {
        case "Testnet": // Kept for backward compatibility.
          return `https://testnet-zkevm.polygonscan.com/tx/${tx}`;
        default:
          return `https://zkevm.polygonscan.com/tx/${tx}`;
      }
    default:
      break;
  }
  return "";
};

export const getValueFromDTPicker = (value: DTValue) => {
  if (value.preset) {
    switch (value.preset) {
      case "now":
        return new Date();
      case "1 minute ago":
        return new Date(Date.now() - 60 * 1000);
      case "5 minutes ago":
        return new Date(Date.now() - 5 * 60 * 1000);
      case "15 minutes ago":
        return new Date(Date.now() - 15 * 60 * 1000);
      case "1 hour ago":
        return new Date(Date.now() - 60 * 60 * 1000);
      case "2 hours ago":
        return new Date(Date.now() - 2 * 60 * 60 * 1000);
      case "4 hours ago":
        return new Date(Date.now() - 4 * 60 * 60 * 1000);
      case "12 hours ago":
        return new Date(Date.now() - 12 * 60 * 60 * 1000);
      case "1 day ago":
        return new Date(Date.now() - 24 * 60 * 60 * 1000);
      case "5 days ago":
        return new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);
      case "1 week ago":
        return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      case "1 month ago":
        return new Date(new Date().setMonth(new Date().getMonth() - 1));
      case "3 months ago":
        return new Date(new Date().setMonth(new Date().getMonth() - 3));
      case "6 months ago":
        return new Date(new Date().setMonth(new Date().getMonth() - 6));
      case "1 year ago":
        return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      case "2 years ago":
        return new Date(new Date().setFullYear(new Date().getFullYear() - 2));
      case "5 years ago":
        return new Date(new Date().setFullYear(new Date().getFullYear() - 5));
      case "Earlist Possible":
        return new Date("2022-01-02");
      default:
        return new Date();
    }
  } else {
    return new Date(value.time!);
  }
};

export const subDateFromNow = (timeRange: string) => {
  const splits = timeRange.split(" ");
  switch (splits[1]) {
    case "hours":
      return subHours(new Date(), parseInt(splits[0]));
    case "days":
      return subDays(new Date(), parseInt(splits[0]));
    case "months":
      return subMonths(new Date(), parseInt(splits[0]));
  }
  return new Date();
};

export const getChainIcon = (chain: string) => {
  return chain === "Solana"
    ? SolanaIconImg
    : chain === "Ethereum"
      ? EthereumIconImg
      : chain === "Polygon zkEVM"
        ? PolygonIconImg
        : "";
};

export const makeChainTooltipText = (chain: string, network: string) => {
  return `View on ${chain} Explorer (${network})`;
};
